import './App.css';
import Home from "./home/home.js";
import Aboutus from './Aboutus/Aboutus.js';
import JotFormIframe from './register/Registerpage.js';
// import "react-bootstrap"

function App() {
  return (
    <div className="App">
    <Home />
    <Aboutus /> 
    <JotFormIframe />
    
    </div>
  );
}

export default App;
