import React from "react";
import "./home.css";
import BannerImage from '../Images/1.jpg';



export default function Home() {


    return (

        <div id="container1">
        <div className="img">
        <img src={BannerImage} alt="Career Coaching" />    
        </div>
        
        <div class="text">
            <h1>Empower Your Career Path</h1>
            <h2>With Expert Guidance</h2>
            <p>Unlock your potential with our Services & Coaching</p>
        </div>
    </div>
    );
}