import React from 'react';

import './Registerpage.css';

const JotFormIframe = () => {
  return (
    <div id="formcontainer">
        <iframe
      id="JotFormIFrame-241244215458453"
      title="Registration Form"
      onload="window.parent.scrollTo(0,0)"
      allowTransparency="true"
      allow="geolocation; microphone; camera; fullscreen"
      src="https://form.jotform.com/241244215458453"
      frameborder="0"
      style={{ minWidth: '100%', maxWidth: '100%', height: '400vh', border: 'none' }}
      scrolling="no"
    ></iframe>
    </div>
    
  );
}

export default JotFormIframe;
