import React from "react";
import "./aboutus.css";
import Image1 from '../Images/card1.jpg';
import Image2 from '../Images/card2.jpg';
import Image3 from '../Images/card3.jpg';
import Image4 from '../Images/card4.jpg';
import Image5 from '../Images/card5.jpg';
import Image6 from '../Images/card6.jpg';



export default function Aboutus() {
   
    return (
        <section class="cards-container">
  <article class="card">
    <img class="card__background" src={Image1} alt="Professional background image for $70 plan"/>
    <div class="card__content">
      <h2 class="card__title">$70</h2>
      <p class="card__description">Services Included: Resume Writing</p>
      <button class="card__button" onClick={() => window.location.href = 'https://www.paypal.com/ncp/payment/RPKEEKTS66MRS'}>Sign Up</button>
    </div>
  </article>
  <article class="card">
    <img class="card__background" src={Image2} alt="Professional background image for $139 plan"/>
    <div class="card__content">
      <h2 class="card__title">$139</h2>
      <p class="card__description">Services Included: Resume Writing, Cover Letter Writing, LinkedIn Optimization</p>
      <button class="card__button" onClick={() => window.location.href = 'https://www.paypal.com/ncp/payment/VZPCKZ2HBHFHQ'}>Sign Up</button>
    </div>
  </article>
  <article class="card">
    <img class="card__background" src={Image3} alt="Professional background image for $499 plan"/>
    <div class="card__content">
      <h2 class="card__title">$499</h2>
      <p class="card__description">Services Included: Resume Writing, Cover Letter Writing, LinkedIn Optimization, Portfolio Website.
    </p>
    <button class="card__button" onClick={() => window.location.href = 'https://www.paypal.com/ncp/payment/EF4BU4CNJKDQC'}>Sign Up</button>
    </div>
  </article>
  <article class="card">
    <img class="card__background" src={Image4} alt="Professional background image for $999 plan"/>
    <div class="card__content">
      <h2 class="card__title">$999</h2>
      <p class="card__description">Services Included: Resume Writing, Cover Letter Writing, LinkedIn Optimization, 5 Interview Counselling session, Portfolio Website.</p>
      <button class="card__button" onClick={() => window.location.href = 'https://www.paypal.com/ncp/payment/FP8FF3RL7UNXY'}>Sign Up</button>
    </div>
  </article>
  <article class="card">
    <img class="card__background" src={Image5} alt="Professional background image for $1499 plan"/>
    <div class="card__content">
      <h2 class="card__title">$1499</h2>
      <p class="card__description">Services Included: Executive Coaching, Services Included: Resume Writing, Cover Letter Writing, LinkedIn Optimization, 15 Interview Counselling session, Portfolio Website.</p>
      <button class="card__button" onClick={() => window.location.href = 'https://www.paypal.com/ncp/payment/Y9J5E2ERPDZHN'}>Sign Up</button>
    </div>
  </article>
  <article class="card">
    <img class="card__background" src={Image6} alt="Professional background image for $1999 plan"/>
    <div class="card__content">
      <h2 class="card__title">$1999</h2>
      <p class="card__description">Services Included:Services Included: Executive Coaching, Services Included: Resume Writing, Cover Letter Writing, LinkedIn Optimization, 60 Interview Counselling session, Portfolio Website, Job Search Assistance.</p>
      <button class="card__button" onClick={() => window.location.href = 'https://www.paypal.com/ncp/payment/LPGWRN3XHW4DN'}>Sign Up</button>
    </div>
  </article>
</section>

    )
}